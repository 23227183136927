.menu-main
#menu-accessibility
#menu-breadcrumb
#menu-top
#menu-mobile
.menu-sidebar
form
.tab-nav
.btn
.button
.more
.paging
.pager
.prev
.next
.menu-header
.box-insurance
.footer__main .grid
.footer__line
.box-carousel
	display none
