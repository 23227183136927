.header
	border-bottom 2pt solid #000
	padding 0 0 10pt
	margin 0 auto 10pt

#logo
	font-size 100%
	line-height 1
	margin 0
	padding 0
	font-weight normal
	img
		border none

.footer
	border-top 2pt solid #000
	padding 10pt 0 0
	margin 10pt 0 0
