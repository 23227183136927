table
	margin 15px 1pt 35px
	text-align left
	border-collapse collapse
	width 98% !important
	font-size 8pt
tr
	page-break-inside avoid
th
td
	border 1pt solid #000
	padding 4px 10px 4px 10px
th
	font-weight bold
